import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import AuthProvider from "./contexts/AuthProvider";
import { Toaster } from "react-hot-toast";
import './style.css';

ReactDOM.render(
  <>
    <Toaster

    ></Toaster>
    <AuthProvider>

      <App />
    </AuthProvider>
  </>
  ,
  document.getElementById("root")
);

serviceWorkerRegistration.register();
